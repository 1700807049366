import React from 'react';
import { Zoom } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import { useRef } from "react";
import Swal from 'sweetalert2';

import './nosotros.css';

import Menu from "../Menu/Menu.jsx";
import Footer from '../Footer/Footer.jsx';

import talkingImages from '../../Assets/Images/talking-8734281_1280.webp';

const Nosotros = () => {

    let topRef = useRef(null);

    function handleSubmit (event) {
        event.preventDefault();

        fetch('https://api.sbsuministrosltda.com/nuevocorreo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json' // Establece el tipo de contenido como JSON
            },
            body: JSON.stringify({
                correo: event.target[0].value.trim(),
            })
        })
        .then(response => {
            if(response.status == 200){
                Swal.fire({
                    title: '¡Gracias por unirte a nosotros!',
                    text: 'El correo se ha registrado correctamente',
                    icon: 'success',
                    confirmButtonText: 'cool'
                })

                document.getElementById('formCorreo').reset();

            } else {
                Swal.fire({
                    title: 'oops! tuvimos un problema',
                    text: 'ah ocurrido un error al intentar registrar tu correo',
                    icon: 'error',
                    confirmButtonText: 'intentar nuevamente'
                })
            }
        })
        .catch(err => console.log(err));
    }

    return (
        <div>

            <Menu />

            <section className='containerAboutInfo'>
                <div className='column1'>
                    <img src={talkingImages}></img>
                    <Link to="/contacto" onClick={() => { topRef.current.scrollIntoView(true) }}><button>contactanos</button></Link>
                </div>

                <article className='column2'>
                    <h2>¿Quién es SB Suministros LTDA?</h2>

                    <div className='aboutUs'>
                        <p>Somos una empresa con experiencia en la comercialización de productos de ferretería de primera calidad; dada nuestra política flexible en precios justos, excelentes inventarios, en el constante apoyo a nuestros clientes y ágiles entregas en cualquier parte del país, pretendemos fortalecer dicho mercado y posicionarnos en el sector.</p>

                        <p>Con el ánimo de brindar una atención personalizada y especializada a su empresa, queremos poner a su disposición nuestro portafolio de productos entre los que se destacan Herramientas manuales y eléctricas, seguridad industrial, soldaduras, tuberías y accesorios para vapor, láminas y rollos, platinas y ángulos, materiales para construcción, pinturas, eléctricos y ferretería en general.</p>
                    </div>

                </article>
            </section>

            <section className='containerTargets'>

                <p className="paragraftInfo">Revelando nuestros <span>valores fundamentales</span> para un impacto perdurable.</p>

                <Zoom cascade>
                    <article className='targetsInfo'>
                        <div className='targets'>
                            <h3>MISION</h3>
                            <p>Ser el referente de excelencia y confianza en el suministro de materiales y servicios de construcción. Aspiramos a ser el socio estratégico preferido, brindando soluciones completas y a medida para los proyectos de nuestros clientes. Nos esforzamos por establecer relaciones a largo plazo basadas en la transparencia, colaboración y satisfacción mutua. Buscamos liderar en la adopción de tecnologías vanguardistas y prácticas sostenibles, impulsando el progreso de la industria y contribuyendo al desarrollo sostenible de nuestras comunidades.</p>
                        </div>
                        <div className='targets'>
                            <h3>CLIENTES</h3>
                            <p>Nos dedicamos a ser el proveedor líder de materiales y servicios de construcción, ofreciendo soluciones integrales y de alta calidad. Nuestro compromiso es suministrar productos confiables, duraderos y sostenibles, y brindar servicios excepcionales que superen las expectativas de nuestros clientes en cada proyecto. Nos esforzamos por construir relaciones sólidas y duraderas, basadas en la transparencia, la colaboración y la satisfacción mutua. Con un enfoque en la innovación y la excelencia, trabajamos para apoyar el crecimiento y éxito de nuestros clientes en la industria de la construcción.</p>
                        </div>
                        <div className='targets'>
                            <h3>VISION</h3>
                            <p>Ser el proveedor líder de materiales y servicios de construcción, ofreciendo soluciones integrales y de alta calidad. Nos comprometemos a suministrar productos confiables, duraderos y sostenibles, y a brindar servicios excepcionales que satisfagan las necesidades y expectativas de nuestros clientes en cada proyecto. Buscamos contribuir al desarrollo de la industria, impulsando la innovación, eficiencia y excelencia en todos nuestros procesos.</p>
                        </div>
                    </article>
                </Zoom>

                <p className="InfoformEmail">¡Mantente en contacto!<br /><br />
                Nos encantaría seguir en contacto contigo y mantenerte al tanto de nuestras novedades, ofertas especiales, y contenido exclusivo. Deja tu correo electrónico a continuación y asegúrate de no perderte nada.</p>

                <form id='formCorreo' className='formEmail' onSubmit={handleSubmit} >
                    <input className='input' type="email" name="correoUser" placeholder="Ingresa tu correo" required />
                    <button>!UNIRME¡ <span className="icon-user-plus"></span></button>
                </form>
            </section>

            <section className="aboutTeam">
                <article className='targetTeam'>
                    <h3>NUESTRA LABOR</h3>
                    <p>Nuestra labor como empresa proveedora de materiales y servicios de construcción es fundamental en la industria de la construcción. Nos dedicamos a ofrecer soluciones integrales y de alta calidad para satisfacer las necesidades de nuestros clientes y contribuir al éxito de sus proyectos</p>
                </article>

                <article className='targetTeam'>
                    <h3>NUESTRO EQUIPO</h3>
                    <p>Nuestro equipo está compuesto por profesionales altamente capacitados y apasionados por la industria de la construcción. Cada miembro de nuestro equipo aporta una amplia experiencia y conocimientos especializados en su campo, lo que nos permite ofrecer un servicio integral y de alta calidad a nuestros clientes</p>
                </article>
            </section>

            <div className="banerContact">
                <p>¿Qué esperas para contactarnos y aprovechar nuestras ofertas y servicios?</p>
                <Link to="/contacto" onClick={() => { topRef.current.scrollIntoView(true) }}><button>Contactanos</button></Link>
            </div>

            <Footer />

        </div>
    );
};

export default Nosotros;