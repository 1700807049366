import React from 'react';

import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { useRef, useState, useEffect } from "react";

import './home.css';

import Menu from "../Menu/Menu.jsx";
import Footer from '../Footer/Footer.jsx';

// Import imagenes
import fondoImagen from '../../Assets/Images/fondoHome.jpg';
import imagen1 from '../../Assets/Images/SOLDADURAIMG2.jpg';
import imagen2 from '../../Assets/Images/TALADROIMG4.jpg';
import imagen3 from '../../Assets/Images/TALADROSIMG3.jpg';
import imagen4 from '../../Assets/Images/TUBOSIMG1.jpg';
import imagen5 from '../../Assets/Images/LAMINAACERO.jpg';
import imagen6 from '../../Assets/Images/equipoConstruIMG6.jpg';
import imagen7 from '../../Assets/Images/construction-assistant.gif';
import patient from '../../Assets/Images/patient.png';
import roadmap from '../../Assets/Images/road-map.png';
import quality from '../../Assets/Images/quality.png';
import newproduct from '../../Assets/Images/new-product.png';

import useCountAnimation from './useCountAnimation.js';

const Home = () => {

    const [clients, clientsRef] = useCountAnimation(200);
    const [cities, citiesRef] = useCountAnimation(10);
    const [suppliers, suppliersRef] = useCountAnimation(10);
    const [qualityValue, qualityRef] = useCountAnimation(100);
    const [data, getData] = useState();
    let topRef = useRef(null);

    // search the products
    const searchProduct = async () => {
        const response = await fetch(`https://api.sbsuministrosltda.com/productos`)
        const json = await response.json();
        getData(getRandomItems(json, 3));
    }

    function getRandomItems(array, numItems) {
        // Mezcla el array de manera aleatoria usando sort y Math.random
        const shuffled = array.sort(() => 0.5 - Math.random());
        // Toma los primeros `numItems` elementos del array mezclado
        return shuffled.slice(0, numItems);
    }

    useEffect(() => {
        searchProduct();
    },[])

    return (
        <div>

            <Menu />

            <section className="containerHome">
                <img src={fondoImagen} alt="fondo inicio" />

                <article className="infoHome">
                    <h1>Bienvenidos a SB Suministros LTDA</h1>
                    <p>Tu socio confiable en materiales de construcción de alta calidad.</p>
                    <a href="https://wa.me/573237999282?text=Hola,%20quiero%20conocer%20m%C3%A1s%20productos" target='_blank'><button>contactanos <span className="icon-whatsapp"></span></button></a>
                </article>
            </section>

            <section className="containerInfo">
                <img src={imagen7} alt="imagen referencia 7"></img>

                <article>
                    <h2>Confíe en nuestra experiencia</h2>

                    <p>En <span>SB Suministros LTDA</span>, somos su socio de confianza en suministros de construcción. Con años de experiencia y un firme compromiso con la calidad, ofrecemos productos de primera y un servicio al cliente excepcional. Deje que nuestra experiencia y profesionalismo impulsen el éxito de sus proyectos.</p>

                    <Link to="/nosotros" onClick={() => { topRef.current.scrollIntoView(true) }}><button>conócenos</button></Link>
                </article>
            </section>

            <section className="containerImages">
                <Fade>
                    <img src={imagen1} alt="imagen de referecia 1"></img>
                    <img src={imagen2} alt="imagen de referecia 2"></img>
                    <img src={imagen3} alt="imagen de referecia 3"></img>
                    <img src={imagen4} alt="imagen de referecia 4"></img>
                    <img src={imagen5} alt="imagen de referecia 5"></img>
                    <img src={imagen6} alt="imagen de referecia 6"></img>
                </Fade>

            </section>

            <h3 className="titleProdSellMore">Los mas vendidos</h3>
            <p className="textProdSellMore">Descubre nuestros productos más populares entre los clientes.</p>

            <article className="boxProductMoreSell">
                { (data !== undefined) ?
                        data.slice(0, 3).map((value) => {

                        let urlWhatsapp = 'https://wa.me/573237999282?text=' + value.NOMBREPROD;

                        return <div className="card">
                            <img className="card-image" src={value.URLIMG1} alt="product mas vendido"></img>
                            <div className="card-content">
                                <h3>{value.NOMBREPROD}</h3>
                            </div>
                            <a href={urlWhatsapp} target='_blank'><button className="card-button">Preguntar <span className="icon-whatsapp"></span></button></a>
                        </div>

                    })
                    :
                    <p>Buscando productos</p>
                }
            </article>

            <div className="btnSellMore">
                <Link to="/servicios" onClick={() => { topRef.current.scrollIntoView(true) }}><button>¡Descubre más productos!</button></Link>
            </div>

            <section className="countExperience">
                <div>
                    <img src={patient} alt="infoExperiences"></img>
                    <p ref={clientsRef}>+{clients}</p>
                    <span>Clientes</span>
                </div>
                <div>
                    <img src={roadmap} alt="infoExperiences"></img>
                    <p ref={citiesRef}>+{cities}</p>
                    <span>Ciudades</span>
                </div>
                <div>
                    <img src={newproduct} alt="infoExperiences"></img>
                    <p ref={suppliersRef}>+{suppliers}</p>
                    <span>Proveedores</span>
                </div>
                <div>
                    <img src={quality} alt="infoExperiences"></img>
                    <p ref={qualityRef}>%{qualityValue}</p>
                    <span>Calidad</span>
                </div>
            </section>

            <Footer />

        </div>

    );
};

export default Home;